import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { getEnvFromHost } from 'utils/env';
import { ConvivaConfig, DatadogConfig } from 'store/config';
import { getDeviceModelYear } from 'utils/platform';

let initialized = false;

export const initializeDatadog = async (
  datadogConfig: DatadogConfig,
  convivaConfig: ConvivaConfig,
) => {
  if (!initialized) {
    initialized = true;

    const env = getEnvFromHost();
    const version = __VERSION__;

    const { botPattern, logs, rum } = datadogConfig;

    const { defaultPrivacyLevel: dpl, enabled: rumEnabled, team: rumTeam, ...rumConfig } = rum;
    const defaultPrivacyLevel = dpl as DefaultPrivacyLevel;

    const regex = new RegExp(botPattern, 'i');
    const shouldFilterBots = regex.test(navigator.userAgent);

    if (__NODE_ENV__ === 'production' && rumEnabled) {
      const { datadogRum } = await import('@datadog/browser-rum');
      datadogRum.init({
        ...rumConfig,
        defaultPrivacyLevel,
        env,
        sessionSampleRate: shouldFilterBots ? 0 : rumConfig.sessionSampleRate,
        version,
      });
      datadogRum.setGlobalContext({
        configShortSha: rumConfig.configShortSha,
        configVersion: rumConfig.configVersion,
        model: convivaConfig.deviceMetadata.deviceModel,
        modelYear: await getDeviceModelYear(),
        team: rumTeam,
      });
    }

    const { enabled: logsEnabled, team: logsTeam, ...logsConfig } = logs;

    if (logsEnabled) {
      const { datadogLogs } = await import('@datadog/browser-logs');
      datadogLogs.init({
        ...logsConfig,
        env,
        sessionSampleRate: shouldFilterBots ? 0 : logsConfig.sessionSampleRate,
        version,
      });
      datadogLogs.setGlobalContext({
        configShortSha: logsConfig.configShortSha,
        configVersion: logsConfig.configVersion,
        model: convivaConfig.deviceMetadata.deviceModel,
        modelYear: await getDeviceModelYear(),
        team: logsTeam,
      });
    }
  }
};
